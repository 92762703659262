<template>
  <v-dialog id="dialog" v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn small v-on="on" class="shiny-button">
        <v-icon small class="mr-1">mdi-plus</v-icon>
        <span v-if="first">
          ADD AI MODEL ENDPOINT
        </span>
        <span v-else>
          ADD AI MODEL
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="ml-3">
          Add model
        </span>
        <pre>          
        </pre>
      </v-card-title>
      <v-card-text>
        <v-layout wrap class="justify-start">
          <v-flex lg6 md6 sm12>
            <v-subheader class="">
              Model name
            </v-subheader>
          </v-flex>
          <v-flex lg6 md6 sm12 class="pr-7">
            <v-flex ref="nameForm" v-model="nameValid">
            <v-text-field dense outlined required persistent-hint hint="for example 'gpt-4'" :rules="nameRules"
                v-model="model.name" />
            </v-flex>
          </v-flex>
        </v-layout>        

        <v-layout wrap class="justify-start mt-3">
          <v-flex lg6 md6 sm12>
            <v-subheader class="">
              Server URL
            </v-subheader>
          </v-flex>
          <v-flex lg6 md6 sm12 class="pr-7">
            <v-form ref="urlForm" v-model="urlValid">
              <v-text-field outlined dense required persistent-hint hint="http://my-ai-server.example.com/api/generate"
                :rules="urlRules" v-model="model.serverUrl" />
            </v-form>
          </v-flex>
        </v-layout>

        <v-divider class="mt-3"></v-divider>

        <v-layout wrap class="justify-start mt-3">
          <v-flex lg6 md6 sm12>
            <v-subheader class="">
              Model Type
            </v-subheader>
          </v-flex>
          <v-flex lg6 md6 sm12>
            <v-radio-group v-model="modelType" dense>
              <v-radio label="Text (chat completions)" value="text"></v-radio>
              <v-radio label="Image (Stable Diffusion, DALL-E)" value="image"></v-radio>                          
            </v-radio-group>
          </v-flex>
        </v-layout>

        <v-layout wrap class="justify-start mt-3" v-if="modelType === 'text'">
          <v-flex lg6 md6 sm12>
            <v-subheader class="">
              API adapter
            </v-subheader>
          </v-flex>
          <v-flex lg6 md6 sm12 class="pr-7">
            <v-select
              dense
              hint="For text adapters help counting the tokens"
              persistent-hint
              v-model="apiType"
              :items="apiTypes"
              item-text="name"
              item-value="value"        
            ></v-select>
          </v-flex>
        </v-layout>

        <v-divider class="mt-3"></v-divider>

        <v-expansion-panels flat class="mt-3">
          <v-expansion-panel>
            <v-expansion-panel-header style="padding-left: 16px;">
              <v-subheader class="" style="padding-left: 0px;">
                Authentication
              </v-subheader>              
            </v-expansion-panel-header>
            <v-expansion-panel-content style="padding-left: 0px;">
              
              <v-layout wrap class="justify-start">
                <v-flex lg6 md6 sm12>
                  <v-radio-group v-model="authMode" dense>
                    <v-radio label="No authentication" :value="'none'"></v-radio>
                    <v-radio label="Username and password" value="basic"></v-radio>
                    <v-radio label="Header (authorization token)" value="header"></v-radio>
                  </v-radio-group>
                </v-flex>

                <v-flex lg6 md6 sm12>
                  <div v-if="authMode === 'basic'" class="mt-4">
                    <v-text-field dense outlined persistent-hint hint="Username"
                      v-model="auth.username" />
                    
                    <v-text-field dense outlined persistent-hint hint="Password" v-model="auth.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword" autocomplete="new-password"></v-text-field>
                  </div>

                  <div v-if="authMode === 'header'" class="mt-4">
                    <v-text-field dense outlined persistent-hint hint="Header"
                      autocomplete="header-key" v-model="auth.headerKey" />
                    
                      <v-text-field dense outlined persistent-hint hint="Token"
                        autocomplete="header-value" v-model="auth.headerValue" />
                  </div>
                  
                
                </v-flex>
              </v-layout>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>        

      </v-card-text>
      <v-card-actions>        
        <v-btn text small outlined class="secondary--text" @click="resetForm">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="disabled" class="mr-2" small @click="createModel()">
          Create
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>
    
<script>

export default {
  components: {
  },

  data() {
    let modelName = ''
    if (this.first) {
      modelName = 'ollama, openai, etc.'
    }
    return {
      step: 1,
      dialog: false,
      modelType: 'text',            
      model: {
        name: modelName,
        serverUrl: ''
      },
      nameValid: false,
      nameRules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 20 || 'Max 20 characters',
        value => (value || '').length >= 3 || 'Min 3 characters',
        value => {
          const pattern = /^[a-zA-Z0-9-_]+$/
          return pattern.test(value) || `Invalid name, only letters, numbers and '-' and '_' are allowed.`
        },
      ],
      urlValid: false,
      urlRules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 200 || 'Max 200 characters',
        // value => (value || '').length >= 3 || 'Min 3 characters', 'http://'
        value => {
          // Pattern from https://stackoverflow.com/a/3809435/1098564 

          // const pattern = /^(http|https):\/\/[a-zA-Z0-9-_.]+(\/[a-zA-Z0-9-_.]+)*$/
          return this.isURL(value) || `Invalid URL.`
        },
      ],
      apiType: 'ollama',
      apiTypes: [
        {
          name: 'OpenAI',
          value: 'openai',
        },
        {
          name: 'Ollama',
          value: 'ollama',
        },
        {
          name: 'Other',
          value: 'other',
        },
      ],

      authMode: 'none',
      showPassword: false,
      auth: {
        username: '',
        password: '',
        headerKey: '',
        headerValue: '',
        replicateToken: '',
      },

      checkPayload: `{
  "prompt": "astronaut riding a horse"
}`
    }
  },

  props: {
    first: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    loading() {
      return this.$store.state.model.loading
    },
    error() {
      return this.$store.state.model.error
    },
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    checkServer() {
      return this.$store.state.model.checkServer
    },
    checkServerLoading() {
      return this.$store.state.model.checkServerLoading
    },
    disabled() {
      return !this.nameValid || !this.urlValid || this.name === ''
    }
  },

  methods: {
    // completeStepOne() {
    //   if (this.modelType === 'replicate') {
    //     // If model is replicate, we can set the endpoint and skip the next step
    //     this.model.serverUrl = 'https://api.replicate.com/v1/predictions'
    //     this.step = 3
    //     return
    //   }
    //   this.step++
    // },
    // completeStepAuthentication() {
    //   if (this.modelType === 'replicate') {
    //     if (this.auth.replicateToken === '') {
    //       console.log('replicate token cannot be empty')
    //       return
    //     } 
    //     this.authMode = 'header'
    //     this.auth.headerKey = 'Authorization'
    //     this.auth.headerValue = `Token ${this.auth.replicateToken}`
    //     this.step = 5
    //     return
    //   }
    //   this.step++
    // },
    async createModel() {
      let headers = {}
      if (this.authMode === 'header') {
        headers[this.auth.headerKey] = this.auth.headerValue
      }

      let concurrency = 1
      let apiType = ''

      if (this.modelType === 'text') {
        concurrency = 10
        apiType = this.apiType
      }

      let serverUrl = this.model.serverUrl.trim()

      let payload = {
        name: this.model.name,
        projectId: this.project.id,
        modelType: this.modelType,
        apiType: apiType,
        servers: [
          {
            url: serverUrl,
            concurrency: concurrency,
            config: {
              username: this.auth.username,
              password: this.auth.password,
              headers: headers
            }
          }
        ]
      }

      await this.$store.dispatch('SetModel', payload)
      if (this.error === null) {
        console.log(this.error)
        let q = {
          projectId: this.project.id
        }
        this.$store.dispatch('ListModels', q)
        this.resetForm()
      }
    },

    resetForm() {
      // closing dialog
      this.dialog = false
      this.step = 1

      // resetting form
      this.model.name = ''
      this.model.serverUrl = ''
    },

    isURL(str) {
      if (str.length > 200 || str.length < 1) {
        return false
      }
      try {
        new URL(str);
        return true;
      } catch (err) {
        return false;
      }
    }
  }
}

</script>